import React, { ReactElement, useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import {
  BlogTitle,
  ArticleContent,
  ArticleWrapper,
  BlogContent,
  AuthorInfoContainer,
  AuthorInfoContent,
  AuthorTitle,
  AuthorName,
  AuthorDescription,
  AuthorPhotoDesc,
  AuthorPhotoMob,
  ShareBlock,
  ArticleContentWrapper,
  ShareBlockContent,
  IconsFixedContainer,
  MainImgWrapper,
  MainBannerMob,
  MainBannerDesc,
  ShareButtonContent,
  ShareIcon,
  ShareButton,
  ShareBlockMob,
  ImgOpacity,
  AuthorInfo,
  ArticleShadow,
} from "./styled";
import { articlesData } from "./ArticlesData";
import { ArticleData } from "./types";
import NavBar from "../NavBar";
import Footer from "../Footer";
import ShareModal from "./ShareModal";
import iconsData from "./IconsData";

interface ArticleProps {
  categoryName: string;
  articleId: string;
}

interface PropsWrapper {
  pageContext: ArticleProps;
}
const DefaultContentComponent = () => {
  return <div></div>;
};
const defaultArticle = {
  title: "",
  icon: "",
  label: "",
  categoryName: "",
  authorInfo: "",
  content: <DefaultContentComponent />,
  articleId: "",
  description: "",
  hashtag: null,
};

const Article = ({ pageContext }: PropsWrapper): ReactElement => {
  const { categoryName, articleId } = pageContext;
  const [isPending, setIsPending] = useState(true);
  const [recommendedPostsNumber, setRecommendedPostsNumber] = useState(3);
  const [articleForModal, setArticleForModal] = useState<any>(defaultArticle);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isBrowser = typeof window !== undefined;
  useEffect(() => {
    setIsPending(false);
  }, []);
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const articleData = articlesData.find((article: ArticleData) => {
    return article.articleId === articleId;
  });
  const getPostsNumber = () => {
    let postsNumber = 3;
    if (window.innerWidth > 767) {
      postsNumber = 2;
    }
    if (window.innerWidth > 1200) {
      postsNumber = 3;
    }
    return postsNumber;
  };
  const changePostsNumber = () => {
    const postsNumber = getPostsNumber();
    setRecommendedPostsNumber(postsNumber);
  };
  useEffect(() => {
    if (isBrowser) {
      changePostsNumber();
    }
  }, [categoryName]);
  const articleContentRef: any = useRef(null);
  const authorContentRef: any = useRef(null);
  const iconsContentRef: any = useRef(null);
  function isInViewport() {
    const articleContentRect =
      articleContentRef?.current &&
      articleContentRef?.current.getBoundingClientRect();
    const authorContentRect =
      articleContentRef?.current &&
      authorContentRef?.current.getBoundingClientRect();
    const iconsContentRect =
      articleContentRef?.current &&
      iconsContentRef?.current.getBoundingClientRect();
    if (
      articleContentRef.current &&
      iconsContentRect.height > authorContentRect.top - 150
    ) {
      return false;
    }
    if (articleContentRef.current) {
      return articleContentRect.top <= -90;
    }
    return false;
  }
  const changeShareContainerTopBottom = () => {
    const rect1 = authorContentRef.current.getBoundingClientRect();
    const shareElement = shareContainer.current;
    if (rect1.top > window.innerHeight - 150 && shareElement) {
      shareElement.style.top = "80px";
      shareElement.style.bottom = "auto";
    }
    if (rect1.top < window.innerHeight - 150 && shareElement) {
      shareElement.style.top = "auto";
      shareElement.style.bottom = "100px";
    }
  };
  const shareContainer: any = useRef(null);
  const changeShareContainerPosition = () => {
    const shareElement = shareContainer.current;
    if (isInViewport() && shareElement) {
      shareElement.style.position = "fixed";
      shareElement.style.top = "100px";
      shareElement.style.bottom = "auto";
    } else {
      if (shareElement) {
        shareElement.style.position = "absolute";
        shareElement.style.top = "80px";
        changeShareContainerTopBottom();
      }
    }
  };

  useEffect(() => {
    if (isBrowser) {
      window.addEventListener("resize", changePostsNumber);
      window.addEventListener("scroll", changeShareContainerPosition);
      return () => {
        window.removeEventListener("resize", changePostsNumber);
      };
    }
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{articleData ? articleData.title : ""}</title>
        <meta
          name="description"
          content={`${articleData ? articleData.description : ""} ${
            articleData && articleData.hashtag ? articleData.hashtag : ""
          }`}
        />
        <meta
          name="keywords"
          content={
            articleData && articleData.hashtag ? articleData.hashtag : ""
          }
        />
        <meta
          property="og:title"
          content={articleData ? articleData.title : ""}
        />
        <meta
          property="og:description"
          content={`${articleData ? articleData.description : ""} ${
            articleData && articleData.hashtag ? articleData.hashtag : ""
          }`}
        />
        <meta
          property="og:image"
          content={articleData ? articleData.icon : ""}
        />
        <meta
          property="og:url"
          content={`https://stromee.de/blog/${
            articleData ? articleData.articleTitle : ""
          }`}
        />
        <meta property="og:type" content="article" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="https://stromee.de/" />
        <meta name="twitter:creator" content="stromee" />
      </Helmet>
      <NavBar />
      <div className="container">
        <div className="row">
          <div className="col-12"></div>
        </div>
      </div>
      {articleData && (
        <div>
          <MainImgWrapper>
            <img
              width="68px"
              src={articleData.icon}
              alt="author photo"
              style={{ height: "500px", position: "absolute", width: "auto" }}
            />
            <ImgOpacity></ImgOpacity>
            <MainBannerMob>
              <BlogTitle>
                {articleData ? articleData.titleContent : ""}
              </BlogTitle>
            </MainBannerMob>
          </MainImgWrapper>
          <div>
            <ArticleWrapper>
              <div>
                <ArticleContentWrapper className="col-12">
                  <ArticleShadow />
                  <ArticleContent ref={articleContentRef}>
                    <MainBannerDesc>
                      <BlogTitle>
                        {articleData ? articleData.titleContent : ""}
                      </BlogTitle>
                    </MainBannerDesc>
                    <ShareBlockMob>
                      <ShareButton variant="grayBorder">
                        <ShareButtonContent
                          onClick={(event) => {
                            event.stopPropagation();
                            setIsModalOpen(true);
                            setArticleForModal(articleData);
                          }}
                        >
                          <ShareIcon
                            preserveAspectRatio="xMinYMin meet"
                            viewBox="0 0 24 24"
                          />
                          Teilen
                        </ShareButtonContent>
                      </ShareButton>
                    </ShareBlockMob>
                    <BlogContent>{articleData.content}</BlogContent>
                  </ArticleContent>
                </ArticleContentWrapper>
              </div>
              <ShareBlockContent>
                <ShareBlock ref={shareContainer}>
                  <span>Artikel teilen</span>
                  <div id="textareaContainer" />
                  <IconsFixedContainer ref={iconsContentRef}>
                    {iconsData.map((iconData, index) => {
                      return iconData.shareComponent(
                        `https://stromee.de/blog/${articleData.articleTitle}`,
                        index,
                        articleData
                      );
                    })}
                  </IconsFixedContainer>
                </ShareBlock>
              </ShareBlockContent>
            </ArticleWrapper>
          </div>
          <AuthorInfoContainer ref={authorContentRef}>
            <AuthorInfoContent className="col-12">
              <AuthorPhotoDesc>
                <img
                  width="68px"
                  src={articleData.authorIcon}
                  alt="author photo"
                />
              </AuthorPhotoDesc>
              <AuthorInfo>
                <div style={{ display: "flex" }}>
                  <AuthorPhotoMob>
                    <img
                      width="68px"
                      src={articleData.authorIcon}
                      alt="author photo"
                    />
                  </AuthorPhotoMob>
                  <div>
                    <AuthorTitle>Autor</AuthorTitle>
                    <AuthorName>{articleData.authorName}</AuthorName>
                  </div>
                </div>
                <AuthorDescription>
                  {articleData.authorDescription}
                </AuthorDescription>
              </AuthorInfo>
            </AuthorInfoContent>
          </AuthorInfoContainer>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div>
                  <ShareModal
                    pageUrl={`/blog/${categoryName}`}
                    isOpen={isModalOpen}
                    handleCloseModal={handleCloseModal}
                    articleData={articleForModal}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};
export default Article;
