import React, { ReactElement } from "react";
import { RouteComponentProps } from "@reach/router";

import SEO from "../components/Seo";
import Index from "../components/Blog/Article";

interface ArticleProps extends RouteComponentProps {
  categoryName: string;
  articleId: string;
}

const Article = ({ categoryName, articleId }: ArticleProps): ReactElement => {
  const pageContext = {
    categoryName: categoryName,
    articleId: articleId,
  };
  return (
    <div>
      <SEO title="Article" />
      <Index pageContext={pageContext} />
    </div>
  );
};

export default Article;
